@media (min-width: 1500px) {
  .banner--fadeBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40rem;
  }
}
@media (max-width: 768px) {
  .banner__description {
    font-size: 0.8rem !important;
    width: auto !important;
  }
  .info {
    text-align: start;
    padding-right: 1.2rem;
  }
  .space {
    margin-left: 6px;
  }
  .banner__button {
    font-size: 0.8rem !important;
    border-radius: 4px !important;
    margin-top: 10px;
    margin-bottom: 22px;
  }
  .banner__title {
    font-size: 1.3rem !important;
  }
  .banner {
    height: 273px;
  }
  .banner--fadeBottom {
    height: 1.4rem !important;
    position: absolute;
    width: 92% !important;
  }

  .banner__genre {
    font-size: 15px;
    display: initial !important;
    margin-top: 10px;
  }
  .banner__description {
    display: none;
  }
}
.banner__title {
  font-size: 3rem;
  font-weight: 800;
}
.banner__description {
  width: 45rem;
  line-height: 1.3;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  font-weight: 500;
  font-size: 1rem;
  max-width: 400px;
  height: 80px;
}
.banner--fadeBottom {
  height: 7.4rem;
  width: 100%;
  background-image: linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.61), #111);
}
.banner__buttons {
  display: flex;
  flex-direction: row;
}
.banner__button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.5vw;
  padding: 0.6rem 1.2rem 0.6rem 1rem;
  margin-right: 1rem;
}
.banner__button:hover {
  color: #000;
  background-color: rgba(170, 170, 170, 0.9);
  transition: all 0.2s;
}
.play {
  background-color: white;
  color: black;
}
.info {
  background-color: rgba(109, 109, 110, 0.7);
  color: white;
}
.info:hover {
  background-color: rgb(74, 74, 74);
  color: white;
}

.space {
  margin-left: 4px;
}

.banner__genre {
  font-size: 15px;
  display: none;
}
