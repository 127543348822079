.modal {
    position: relative;
    max-width: 800px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background: #111;
    overflow: hidden;
    border-radius: 8px;
    transition: all 400ms ease-in-out 2s;
    animation: fadeIn 400ms;
  }
  .modal__poster-img {
    width: 100%;
    height: auto;
  }
  .modal__content {
    padding: 40px;
    color: white;
    text-align: left;
  }
  .modal__title {
    padding: 0;
    font-size: 40px;
    margin: 16px 0;
  }
  .modal__details {
    font-weight: 600;
    font-size: 18px;
  }
  .modal__genre {
    font-weight: 600;
    font-size: 18px;
  }
  .modal__overview {
    font-size: 16px;
    line-height: 1.5;
  }

  .modal__score {
    font-weight: 600;
    font-size: 20px;

    padding-top: 20px;
    padding-bottom: 20px;
  }
  .modal__score-star{
    color: orange;
  }
  .modal__user-perc {
    color: #46d369;
    margin-right: 10px;
  }
  .modal__release-date {
    margin-right: 10px;
  }
  .modal::-webkit-scrollbar {
    display: none;
    visibility: hidden;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .modal {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .wrapper-modal {
    position: fixed;
    inset: 0px;
    background-color: rgb(0 0 0 / 71%);
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
  }
  .presentation {
    z-index: 1200;
    position: absolute;
  }
  .modal-close {
    position: absolute;
    right: 23px;
    top: 16px;
    cursor: pointer;
    z-index: 1000;
    color: white;
    font-size: xx-large;
  }
  @media screen and (max-height: 768px) {
    .wrapper-modal {
      align-items: unset;
      padding-top: 2rem;
    }
    .modal {
      overflow-y: scroll;
    }
  }
  @media screen and (max-width: 768px) {
    .modal__overview {
      font-size: 15px;
    }
    .modal__details {
      font-size: 12px;
    }
    .wrapper-modal {
      padding: 0;
    }
    .modal {
      overflow-y: scroll !important;
    }
    .modal__title {
      font-size: 25px;
    }
    .modal__score {
      font-size: 15px;
    }
    .modal__genre {
      font-size: 15px;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  