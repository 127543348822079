.searchContent {
    height: 100vh;
    background-color: black;
  }
  
  .search-container {
    background-color: black;
    width: 100%;
    min-height: calc(100vh - 10rem);
    text-align: center;
    padding: 5rem 0;
  }
  
  .no-results {
    display: flex;
    justify-content: center;
    align-content: center;
    color: #c5c5c5;
    height: calc(100vh - 16rem);
    padding: 8rem;
  }
  
  .movie {
    flex: 1 1 auto;
    display: inline-block;
    padding-right: 0.5rem;
    padding-bottom: 7rem;
  }
  
  .movie__column-poster {
    cursor: pointer;
  }
  
  .movie__poster {
    width: 90%;
    border-radius: 5px;
  }