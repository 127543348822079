.row {
  padding-left: 40px;
  color: white;
}

.row__title__div {
  margin-top: 4rem;
  margin-bottom: 10px;
}

.row__poster__title {
  margin-top: 0px;
}

.row__title {
  font-size: 24px;
}

.slider {
  position: relative;
}
.slider__arrow-left {
  background-clip: content-box;
  padding: 20px 0;
  box-sizing: border-box;
  transition: 400ms all ease-in-out;
  cursor: pointer;
  width: 80px;
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}
.slider__arrow-right {
  padding: 20px 0;
  background-clip: content-box;
  box-sizing: border-box;
  transition: 400ms all ease-in-out;
  cursor: pointer;
  width: 80px;
  z-index: 1000;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}
.arrow {
  transition: 400ms all ease-in-out;
}
.arrow:hover {
  transition: 400ms all ease-in-out;
  transform: scale(1.5);
}
.slider:hover .slider__arrow-left {
  transition: 400ms all ease-in-out;
  visibility: visible;
}
.slider:hover .slider__arrow-right {
  transition: 400ms all ease-in-out;
  visibility: visible;
}
.slider__arrow-left:hover {
  background: rgba(20, 20, 20, 0.5);
  transition: 400ms all ease-in-out;
}
.slider__arrow-right:hover {
  background: rgba(20, 20, 20, 0.5);
  transition: 400ms all ease-in-out;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px 0 20px 20px;
  scroll-behavior: smooth;
}
.row__posters::-webkit-scrollbar {
  display: none;
}
.row__poster {
  width: 100%;
  max-height: 144px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.row__posterLarge {
  max-height: 320px;
}

.row__arrow-left {
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  width: 32px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}
.row__arrow-right {
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  width: 32px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.row__rank {
  font-size: 137px;
  line-height: 22rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 800;
  color: #cdcdcd;
  height: 212px;
  padding-left: 50px;
}
.row__swiper {
  display: flex !important;
  justify-content: space-around;
}

@media screen and (min-width: 1200px) {
  .row__poster {
    max-height: 160px;
  }
  .row__posterLarge {
    max-height: fit-content;
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .row__poster {
    max-height: 100px;
  }
  .row__posterLarge {
    max-height: 280px;
  }
  .row {
    padding-left: 1.3rem;
  }
  .row__title {
    font-size: 15px;
  }
  .row__title__div {
    margin-top: 1.5rem;
    margin-bottom: 5px;
  }

  .row__rank {
    font-size: 28px;
    line-height: 1.5rem;
    height: 169px;
    margin-right: 5px;
    padding-left: 34px;
  }

  #TV .swiper-wrapper .swiper-slide {
    margin-right: 40px !important;
  }
}

.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

.swiper {
  z-index: unset !important;
  position: sticky !important;
}
