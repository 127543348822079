.mini__modal {
    position: relative;
    max-width: 800px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background: #111;
    overflow: hidden;
    border-radius: 8px;
    transition: all 400ms ease-in-out 2s;
    animation: fadeIn 400ms;
  }
  .mini__modal__poster-img {
    width: 100%;
    height: auto;
  }
  .mini__modal__content {
    padding: 25px;
    color: white;
    text-align: left;
  }
  
  .mini__modal__details {
    font-weight: 600;
  }
  .mini__modal__genre {
    font-weight: 600;
    font-size: 15px;
  }
  .mini__modal__overview {
    font-size: 16px;
    line-height: 1.5;
  }

  .mini__modal_title_div {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .mini__modal_score_div {
    padding-bottom: 15px;
  }

  .mini__modal__score {
    font-weight: 600;
    font-size: 15px;
  }
  .mini__modal__title {
    font-size: 25px;
    font-weight: bolder;
    
    position: absolute;
    top: 186px;
    left: 15px;
  }

  .mini__modal__score-star{
    color: orange;
  }
  .mini__modal__user-perc {
    color: #46d369;
    margin-right: 10px;
    font-size: 15px;
  }
  .mini__modal__release-date {
    margin-right: 10px;
    font-size: 15px;
  }
  .mini__modal__episode-num {
    font-size: 15px;
  }

  .mini__modal_dtl_btn {
    float: right;
    cursor: pointer;
    font-size: 20px;
  }

  .mini__modal_dtl_btn:hover {
    font-size: 0;
  }

  .mini__modal_dtl_btn:hover:before{
    content : '▼';
    font-size : 20px;
  }


  .mini__modal::-webkit-scrollbar {
    display: none;
    visibility: hidden;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .mini__modal {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .wrapper-mini-modal {
    position: fixed;
    inset: 0px;
    background-color: rgb(0 0 0 / 71%);
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    
    width: 400px;
    height: 350px;
    border-radius: 8px;
  }
  .presentation-mini {
    z-index: 1200;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mini__modal-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1000;
    color: white;
  }
  @media screen and (max-height: 768px) {
    .wrapper-mini-modal {
      align-items: unset;
      padding-top: 2rem;
    }
    .mini__modal {
      overflow-y: scroll;
    }
  }
  @media screen and (max-width: 768px) {
    .mini__modal__overview {
      font-size: 16px;
    }
    .mini__modal__details {
      font-size: 16px;
    }
    .wrapper-modal {
      padding: 0;
    }
    .mini__modal {
      overflow-y: scroll !important;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  