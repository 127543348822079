.nav {
  position: fixed;
  top:0;
  width: 100%;
  height: 30px; 
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background-color: #111;
}

.nav__logo {
  left: 40px;
  width: 80px;
  object-fit: contain;
  cursor: pointer;
}

.nav__right {
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
}

.nav__avatar{
  width:30px;
  object-fit: contain;
}

.nav__input {
  margin-right: 10px;
  
  background-color: rgba(0, 0, 0, 0.582);
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  height: 20px;

}

