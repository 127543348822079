@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;400;500;600;700;800');

@font-face {
  font-family: 'NetflixSans_W_Bd';
  src: url('../public/fonts/NetflixSans_W_Bd.woff2') format(woff2);
}

@font-face {
  font-family: 'NetflixSans_W_Lt';
  src: url('../public/fonts/NetflixSans_W_Lt.woff2') format(woff2);
}

* {
  margin: 0;
  font-family: 'Nanum NetflixSans_W_Bd', sans-serif;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.app {
  background: #111;
}
